function userCanReview(restaurantToken) {
    const now = Date.now();

    let userReviews = window.localStorage.getItem("__user_has_reviewed__");
    let lastSession = window.localStorage.getItem("__user_session__");

    if (!userReviews) { return true }

    if (userReviews && lastSession) {
        userReviews = JSON.parse(userReviews);
        lastSession = JSON.parse(lastSession);

        const r = userReviews.find(item => item === restaurantToken);

        console.log((Math.abs(lastSession.time - now)))
        if ((Math.abs(lastSession.time - now) <= 3600000) && !r) {
            return true;
        }
    }

    return false;
}

export { userCanReview }
