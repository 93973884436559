import React, { useState, useMemo, useCallback } from 'react';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import { useRestaurant } from '../../utils/contexts/restaurant-context';
import { useCart } from '../../utils/contexts/cart-context';
import ListBeverage from '../list/Beverage';
import MenuBeverage from '../menu/Beverage';
import Row from '../list/Row';
import Preferred from '../list/Preferred';
import TastingList from '../list/TastingList';
import TastingMenu from '../menu/TastingMenu';
import Course from '../menu/Course';
import SearchDrink from './SearchDrink';
import DrinkOP from '../list/Drink';
import Drink from '../menu/Drink';
import ReviewsTab from './ReviewsTab';
import parse from "html-react-parser";
import categoriesBuilder from "../../utils/categoriesBuilder";
import beveragesCategoriesBuilder from '../../utils/beveragesCategoriesBuilder';

const namespaceConfig = {
    morgana: { tab1: "CUCINA" },
    redcafe: { tab1: "FOOD" },
};

function NewTabs({ hasOrders }) {
    const { restaurant } = useRestaurant();
    const { t } = useTranslation();
    const { add } = useCart();

    const [result, setResult] = useState([]);
    const [isSearching, setIsSearching] = useState(false);

    // Calcolo del tab iniziale
    const defaultTab = useMemo(() => {
        if (["mycia", "test"].includes(process.env.REACT_APP_NAMESPACE)) {
            return restaurant.tasting_menu.length === 0 ? 1 : restaurant.tasting_menu.length + 1;
        } else if (["campari", "aperolspritz", "averna", "wild"].includes(process.env.REACT_APP_NAMESPACE) || restaurant.id === 14857) {
            const tmpTabs = [
                "Recensioni",
                ...restaurant.tasting_menu.map(tab => tab.name),
                ...(restaurant.courses.length ? ["Piatti"] : []),
                ...(restaurant.beverages?.length ? ["LISTA VINI/BEVANDE"] : []),
            ];
            return tmpTabs.indexOf("LISTA VINI/BEVANDE");
        }
        return restaurant.tasting_menu.length + 2;
    }, [restaurant]);

    const [activeTab, setActiveTab] = useState(defaultTab);

    const renderCustomTabName = useCallback((idx) => {
        let customName = restaurant.tabs?.[idx] || t(`tab.button_${idx + 1}`);
        if (namespaceConfig[process.env.REACT_APP_NAMESPACE]?.[`tab${idx + 1}`]) {
            customName = namespaceConfig[process.env.REACT_APP_NAMESPACE][`tab${idx + 1}`];
        }
        return customName;
    }, [restaurant.tabs, t]);

    // Renderizza il contenuto delle schede
    const TabTastingMenuContent = useMemo(() => (
        restaurant.has_tasting_menu &&
        restaurant.tasting_menu.map((tastingMenu, idx) => (
            <TabPanel key={`tasting-${idx}`}>
                <p className="px-4 text-base">{parse(tastingMenu.description || tastingMenu.description_raw)}</p>
                {tastingMenu.courses.map((course, i) => (
                    hasOrders
                        ? <TastingList course={course} index={i} key={`tasting-course-${i}`} tastingMenu={tastingMenu} />
                        : <TastingMenu course={course} index={i} key={`tasting-course-${i}`} tastingMenu={tastingMenu} />
                ))}
                <p className="text-center py-4 font-semibold">Prezzo menù completo: {tastingMenu.price}€</p>
                {hasOrders && tastingMenu.courses.length > 0 && (
                    <div className="cta-tasting-menu text-center py-2 px-4 m-4 bg-mycia-green rounded-full">
                        <button type="button" onClick={() => add(tastingMenu, 1)}>Ordina il menu</button>
                    </div>
                )}
            </TabPanel>
        ))
    ), [restaurant.tasting_menu, hasOrders]);

    const TabCoursesContent = useMemo(() => (
        restaurant.courses?.length > 0 && (
            <TabPanel>
                {categoriesBuilder.buildCategoriesTree(restaurant.courses).map((course, i) =>
                    hasOrders
                        ? <Row course={course} index={i} key={`course-${i}`} />
                        : <Course course={course} index={i} key={`course-${i}`} />
                )}
            </TabPanel>
        )
    ), [restaurant.courses, hasOrders]);

    const TabBeveragesContent = useMemo(() => (
        <TabPanel>
            <div className="block w-full m-auto px-4">
                <SearchDrink
                    className="w-full my-2 p-2 text-black dark:text-white border rounded-lg outline-none"
                    categories={restaurant.beverages}
                    setResult={setResult}
                    setIsSearching={setIsSearching}
                />
            </div>
            <div className="beverages">
                {isSearching
                    ? result.map((cat, i) => cat.drinks.length > 0 && (
                        <div key={`beverage-cat-${i}`}>
                            <p className="px-4 py-2 uppercase font-bold">{cat.name}</p>
                            {cat.drinks.map((drink, j) => hasOrders
                                ? <DrinkOP drink={drink} key={`beverage-drink-${j}`} />
                                : <Drink drink={drink} key={`beverage-drink-${j}`} />
                            )}
                        </div>
                    ))
                    : beveragesCategoriesBuilder.buildCategoriesTree(restaurant.beverages).map((beverage, i) =>
                        hasOrders
                            ? <ListBeverage beverage={beverage} index={i} key={`beverage-${i}`} />
                            : <MenuBeverage beverage={beverage} index={i} key={`beverage-${i}`} />
                    )}
            </div>
        </TabPanel>
    ), [restaurant.beverages, isSearching, result, hasOrders]);

    return (
        <Tabs defaultIndex={defaultTab} onSelect={(i) => setActiveTab(i)}>
            <TabList>
                <div className="tabs relative">
                    <div className="bar overflow-x-scroll flex mx-3 py-3">
                        <Tab>
                            <div className={clsx("tab cursor-pointer inline-block mx-2 py-1 px-4 rounded-full border border-solid border-mycia-blue whitespace-nowrap", {
                                "active bg-mycia-blue tab-active text-white": activeTab === 0,
                            })}>
                                RECENSIONI
                            </div>
                        </Tab>
                        {restaurant.tasting_menu.map((tab, i) => (
                            <Tab key={`tasting-tab-${i}`}>
                                <div className={clsx("tab cursor-pointer inline-block mx-2 py-1 px-4 rounded-full border border-solid border-mycia-blue whitespace-nowrap", {
                                    "active bg-mycia-blue tab-active text-white": activeTab === i + 1,
                                })}>
                                    {tab.name}
                                </div>
                            </Tab>
                        ))}
                        {restaurant.courses.length > 0 && (
                            <Tab>
                                <div className={clsx("tab cursor-pointer inline-block mx-2 py-1 px-4 rounded-full border border-solid border-mycia-blue whitespace-nowrap", {
                                    "active bg-mycia-blue tab-active text-white": activeTab === restaurant.tasting_menu.length + 1,
                                })}>
                                    {renderCustomTabName(0)}
                                </div>
                            </Tab>
                        )}
                        <Tab>
                            <div className={clsx("tab cursor-pointer inline-block mx-2 py-1 px-4 rounded-full border border-solid border-mycia-blue whitespace-nowrap", {
                                "active bg-mycia-blue tab-active text-white": activeTab === restaurant.tasting_menu.length + (restaurant.courses?.length ? 2 : 1),
                            })}>
                                {renderCustomTabName(1)}
                            </div>
                        </Tab>
                        {restaurant.token !== "IJwpJoOWW" &&
                            <Tab>
                                <div className={clsx("tab cursor-pointer inline-block mx-2 py-1 px-4 rounded-full border border-solid border-mycia-blue whitespace-nowrap", {
                                    "active bg-mycia-blue tab-active text-white": activeTab === restaurant.tasting_menu.length + (restaurant.courses?.length ? 3 : 2),
                                })}>
                                    {t("tab.button_3")}
                                </div>
                            </Tab>}
                    </div>
                </div>
            </TabList>

            <TabPanel>
                <ReviewsTab />
            </TabPanel>

            {TabTastingMenuContent}
            {TabCoursesContent}
            {TabBeveragesContent}

            <TabPanel>
                <Preferred />
            </TabPanel>
        </Tabs>
    );
}

export default NewTabs;




// import React, { Fragment, useState } from 'react';
// import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
// import { useTranslation } from 'react-i18next';
// import clsx from 'clsx';
// import { useRestaurant } from '../../utils/contexts/restaurant-context';
// import ListBeverage from '../list/Beverage';
// import MenuBeverage from '../menu/Beverage';
// import Row from '../list/Row';
// import Preferred from '../list/Preferred';
// import TastingList from '../list/TastingList';
// import { useCart } from '../../utils/contexts/cart-context';
// import TastingMenu from '../menu/TastingMenu';
// import Course from '../menu/Course';
// import theme from '../../utils/theme';
// import SearchDrink from './SearchDrink';
// import DrinkOP from '../list/Drink';
// import Drink from '../menu/Drink';
// import parse from "html-react-parser";
// import categoriesBuilder from "../../utils/categoriesBuilder";
// import ReviewsTab from './ReviewsTab';
// import beveragesCategoriesBuilder from '../../utils/beveragesCategoriesBuilder';

// function NewTabs(props) {
//     const { hasOrders } = props;
//     const { restaurant } = useRestaurant();
//     const { t } = useTranslation();
//     const { add } = useCart();

//     const [result, setResult] = useState([]);
//     const [isSearching, setIsSearching] = useState(false);

//     let def;
//     if ("mycia" === process.env.REACT_APP_NAMESPACE || "test" === process.env.REACT_APP_NAMESPACE) {
//         if (restaurant.tasting_menu.length === 0) {
//             def = 1;
//         } else {
//             def = restaurant.tasting_menu.length + 1;
//         }
//     } else {
//         def = restaurant.tasting_menu.length + 2;
//     }

//     // if (restaurant.courses.length == 0) {
//     //     def = restaurant.tasting_menu.length + 1;
//     // }

//     if ("campari" === process.env.REACT_APP_NAMESPACE ||
//         "aperolspritz" === process.env.REACT_APP_NAMESPACE ||
//         "averna" === process.env.REACT_APP_NAMESPACE ||
//         "camparisoda" === process.env.REACT_APP_NAMESPACE ||
//         "wild" === process.env.REACT_APP_NAMESPACE ||
//         "espolon" === process.env.REACT_APP_NAMESPACE ||
//         "bulldog" === process.env.REACT_APP_NAMESPACE ||
//         "braulio" === process.env.REACT_APP_NAMESPACE ||
//         "crodinoxl" === process.env.REACT_APP_NAMESPACE ||
//         "redbull" === process.env.REACT_APP_NAMESPACE ||
//         "sanpellegrino" === process.env.REACT_APP_NAMESPACE ||
//         14857 === restaurant.id) {
//         let tmpTabs = [];
//         for (let tab of [{ name: "Recensioni" }, ...restaurant.tasting_menu]) {
//             tmpTabs.push(tab.name);
//         }
//         if (restaurant.courses.length) {
//             tmpTabs.push("Piatti");
//         }
//         if (restaurant.beverages?.length) {
//             tmpTabs.push("LISTA VINI/BEVANDE");
//         }
//         def = (tmpTabs.indexOf("LISTA VINI/BEVANDE"));
//     }

//     const [activeTab, setIsActiveTab] = useState(def);

//     function renderCustomTabName(idx) {
//         if (idx === 0) {
//             let customName = restaurant.tabs.length > 0 && restaurant.tabs[idx];

//             if ("morgana" === process.env.REACT_APP_NAMESPACE) {
//                 customName = "CUCINA";
//             } else if ("redcafe" === process.env.REACT_APP_NAMESPACE) {
//                 customName = "FOOD";
//             } else if (!customName) {
//                 customName = t("tab.button_1");
//             }

//             return customName;
//         } else if (idx === 1) {
//             let customName = restaurant.tabs.length > 0 && restaurant.tabs[idx];

//             if (!customName) {
//                 customName = theme.getCustomElement(restaurant.id, "newtabs", t);
//             }

//             return customName;
//         }
//     }

//     function TabsCrousel() {
//         return <TabList>
//             <div className="tabs relative">
//                 <div className="bar overflow-x-scroll flex mx-3 py-3">
//                     <TabPanel>
//                         <ReviewsTab />
//                     </TabPanel>

//                     <Tab>
//                         <div className={clsx("tab", "cursor-pointer", "inline-block", "mx-2", "py-1", "px-4",
//                             "rounded-full", "border", "border-solid", "border-mycia-blue",
//                             "whitespace-nowrap", { "active bg-mycia-blue tab-active text-white": activeTab === 0 })}>
//                             RECENSIONI
//                         </div>
//                     </Tab>
//                     {restaurant.tasting_menu.map((t, i) => (
//                         <Tab key={i} index={i} label={t.name}>
//                             <div className={clsx("tab", "cursor-pointer", "inline-block", "mx-2", "py-1", "px-4",
//                                 "rounded-full", "border", "border-solid", "border-mycia-blue",
//                                 "whitespace-nowrap", { "active bg-mycia-blue tab-active text-white": activeTab === i + 1 })}>
//                                 {t.name}
//                             </div>
//                         </Tab>
//                     ))}
//                     {restaurant.courses.length > 0 && <Tab>
//                         <div className={clsx("tab", "cursor-pointer", "inline-block", "mx-2", "py-1", "px-4",
//                             "rounded-full", "border", "border-solid", "border-mycia-blue",
//                             "whitespace-nowrap", { "active bg-mycia-blue tab-active text-white": activeTab === restaurant.tasting_menu.length + 1 })}>
//                             {renderCustomTabName(0)}
//                         </div>
//                     </Tab>}
//                     <Tab>
//                         <div className={clsx("tab", "cursor-pointer", "inline-block", "mx-2", "py-1", "px-4",
//                             "rounded-full", "border", "border-solid", "border-mycia-blue",
//                             "whitespace-nowrap", { "active bg-mycia-blue tab-active text-white": activeTab === restaurant.tasting_menu.length + (restaurant.courses && restaurant.courses.length ? 2 : 1) })}>
//                             {renderCustomTabName(1)}
//                         </div>
//                     </Tab>

//                     {restaurant.token === "IJwpJoOWW" ?
//                         null :
//                         <Fragment>
//                             <Tab>
//                                 <div className={clsx("tab", "cursor-pointer", "inline-block", "mx-2", "py-1", "px-4",
//                                     "rounded-full", "border", "border-solid", "border-mycia-blue",
//                                     "whitespace-nowrap", { "active bg-mycia-blue tab-active text-white": activeTab === restaurant.tasting_menu.length + 1 + (restaurant.courses && restaurant.courses.length ? 3 : 2) })}>
//                                     {t("tab.button_3")}
//                                 </div>
//                             </Tab>
//                         </Fragment>}
//                 </div>
//             </div>
//         </TabList>
//     }

//     function TabCoursesContent() {
//         if (hasOrders) {
//             if (restaurant.courses && restaurant.courses.length > 0) {
//                 return <TabPanel>
//                     {(restaurant.courses && restaurant.courses.length > 0) ?
//                         categoriesBuilder.buildCategoriesTree(restaurant.courses).map((course, i) =>
//                             <Row course={course} index={i} key={i} />) :
//                         <p className="text-center">Nessun piatto disponibile</p>}
//                 </TabPanel>
//             } else return null;
//         } else {
//             if (restaurant.courses && restaurant.courses.length > 0) {
//                 return <TabPanel>
//                     {(restaurant.courses && restaurant.courses.length > 0) ?
//                         categoriesBuilder.buildCategoriesTree(restaurant.courses).map((course, i) =>
//                             <Course course={course} index={i} key={i} />) :
//                         <p className="text-center">Nessun piatto disponibile</p>}
//                 </TabPanel>
//             } else return null;
//         }
//     }

//     function TabTastingMenuContent() {
//         if (hasOrders) {
//             return restaurant.has_tasting_menu && restaurant.tasting_menu.map((tastingMenu, idx) => (
//                 <TabPanel key={idx}>
//                     {(String(restaurant.id) === "6608" || String(restaurant.id) === "13040") ?
//                         <p className="px-4 text-base">{parse(tastingMenu.description_raw)}</p> :
//                         <p className="px-4 text-base">{tastingMenu.description}</p>}

//                     {tastingMenu.courses.map((course, i) =>
//                         <TastingList course={course} index={i} key={i} tastingMenu={tastingMenu} />)}

//                     <p className="text-center py-4 pt-6 font-semibold">Prezzo menù completo: {tastingMenu.price}€</p>
//                     {tastingMenu.courses.length !== 0 && <div className="cta-tasting-menu text-center py-2 px-4 m-4 bg-mycia-green rounded-full">
//                         <button type="button" onClick={() => add(tastingMenu, 1)}>Ordina il menu</button>
//                     </div>}
//                 </TabPanel>
//             ))
//         } else {
//             return restaurant.has_tasting_menu && restaurant.tasting_menu.map(tastingMenu => (
//                 <TabPanel>
//                     {(String(restaurant.id) === "6608" || String(restaurant.id) === "13040") ?
//                         <p className="px-4 text-base">{parse(tastingMenu.description_raw)}</p> :
//                         <p className="px-4 text-base">{tastingMenu.description}</p>}

//                     {tastingMenu.courses.map((course, i) =>
//                         <TastingMenu course={course} index={i} key={i} tastingMenu={tastingMenu} />)}

//                     <p className="text-center py-4 font-semibold">Prezzo menù completo: {tastingMenu.price}€</p>
//                 </TabPanel>
//             ))
//         }
//     }

//     function TabBeveragesContent() {
//         if (hasOrders) {
//             return <TabPanel>
//                 <div className='block w-full m-auto px-4'>
//                     <SearchDrink className="w-full my-2 p-2 text-black dark:text-white border rounded-lg outline-none" categories={restaurant.beverages} setResult={setResult} setIsSearching={setIsSearching} />
//                 </div>

//                 <div className="beverages">
//                     {isSearching ?
//                         result.map(cat =>
//                             cat.drinks.length > 0 &&
//                             <div>
//                                 <p className="px-4 py-2 uppercase font-bold">{cat.name}</p>
//                                 {cat.drinks.map(drink =>
//                                     <DrinkOP drink={drink} />)}
//                             </div>) :
//                         (restaurant.beverages && restaurant.beverages.length > 0) &&
//                         beveragesCategoriesBuilder.buildCategoriesTree(restaurant.beverages).map((beverage, i) =>
//                             <ListBeverage beverage={beverage} index={i} key={i} />)}
//                 </div>
//             </TabPanel>
//         } else {
//             return <TabPanel>
//                 <div className='block w-full m-auto px-4'>
//                     <SearchDrink className="w-full my-2 p-2 text-black dark:text-white border rounded-lg outline-none" categories={restaurant.beverages} setResult={setResult} setIsSearching={setIsSearching} />
//                 </div>

//                 <div className="beverages">
//                     {isSearching ?
//                         result.map(cat =>
//                             cat.drinks.length > 0 &&
//                             <div>
//                                 <p className="px-4 py-2 uppercase font-bold">{cat.name}</p>
//                                 {cat.drinks.map(drink =>
//                                     <Drink drink={drink} />)}
//                             </div>) :
//                         (restaurant.beverages && restaurant.beverages.length > 0) &&
//                         beveragesCategoriesBuilder.buildCategoriesTree(restaurant.beverages).map((beverage, i) =>
//                             <MenuBeverage beverage={beverage} index={i} key={i} />)}
//                 </div>
//             </TabPanel>
//         }
//     }

//     return (
//         <Tabs defaultIndex={def} onSelect={(i) => setIsActiveTab(i)}>

//             <TabsCrousel />

//             {String(restaurant.id) === "11945" &&
//                 <div className="m-2">
//                     <input className="px-4 py-2 w-full border rounded" onClick={() => alert("Feature non ancora utilizzabile")} placeholder="Cerca piatto" />
//                 </div>}

//             {/* {(process.env.REACT_APP_NAMESPACE == "pago" || process.env.REACT_APP_NAMESPACE == "sanbitter") ?
//                 <div className="p-4 bg-[#CB0F22]">
//                     <img className="w-1/2 mx-auto" src={`/assets/${process.env.REACT_APP_NAMESPACE}/hero.png`} />
//                 </div> :
//                 null} */}

//             {/* tabs content render here*/}
//             <TabTastingMenuContent />

//             <TabCoursesContent />

//             <TabBeveragesContent />

//             <TabPanel>
//                 <Preferred />
//             </TabPanel>
//             {/* -------------------- */}
//         </Tabs>
//     );
// }

// export default NewTabs;
