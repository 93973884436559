import { Fragment, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import ReviewItem from "./ReviewItem";
import { useRestaurant } from "../../utils/contexts/restaurant-context";
import restaurantClient from "../../logic/restaurant";
import { userCanReview } from "../../utils/reviews";

function ReviewsTab() {
    const [reviews, setReviews] = useState([]);
    const { restaurant } = useRestaurant();
    const history = useHistory();

    useEffect(() => {
        async function getReviews() {
            const reviewData = await restaurantClient.doGetReviews(restaurant.token);

            setReviews(reviewData.data);
        }

        getReviews();
        // setReviews(
        //     [
        //         {
        //             "type": "preferred",
        //             "dishes": [
        //                 {
        //                     "dish": {
        //                         "id": 558381,
        //                         "name": "filetto al pepe verde con carote ",
        //                         "description": null,
        //                         "price": 3,
        //                         "image_path": null,
        //                         "best_seller": false,
        //                         "most_loved": true
        //                     },
        //                     "count": 1
        //                 },
        //                 {
        //                     "dish": {
        //                         "id": 553430,
        //                         "name": "filetto al pepe verde",
        //                         "description": null,
        //                         "price": 5,
        //                         "image_path": null,
        //                         "best_seller": true,
        //                         "most_loved": true
        //                     },
        //                     "count": 7
        //                 },
        //                 {
        //                     "dish": {
        //                         "id": 528432,
        //                         "name": "Cheesecake alla fragola",
        //                         "description": null,
        //                         "price": 6,
        //                         "image_path": "https://ristoratori.mycia.it/assets/piatti/6418_528432_14994_521678_14700_487326_DOLCI_DEL_GIORNO.png",
        //                         "best_seller": false,
        //                         "most_loved": true
        //                     },
        //                     "count": 1
        //                 }
        //             ]
        //         },
        //         {
        //             "type": "ordered",
        //             "dishes": [
        //                 {
        //                     "dish": {
        //                         "id": 528432,
        //                         "name": "Cheesecake alla fragola",
        //                         "description": null,
        //                         "price": 6,
        //                         "image_path": "https://ristoratori.mycia.it/assets/piatti/6418_528432_14994_521678_14700_487326_DOLCI_DEL_GIORNO.png",
        //                         "best_seller": false,
        //                         "most_loved": true
        //                     },
        //                     "count": 15
        //                 },
        //                 {
        //                     "dish": {
        //                         "id": 553430,
        //                         "name": "filetto al pepe verde",
        //                         "description": null,
        //                         "price": 5,
        //                         "image_path": null,
        //                         "best_seller": true,
        //                         "most_loved": true
        //                     },
        //                     "count": 6
        //                 },
        //                 {
        //                     "dish": {
        //                         "id": 558381,
        //                         "name": "filetto al pepe verde con carote ",
        //                         "description": null,
        //                         "price": 3,
        //                         "image_path": null,
        //                         "best_seller": false,
        //                         "most_loved": true
        //                     },
        //                     "count": 6
        //                 }
        //             ]
        //         }
        //     ]
        // )
    }, [restaurant.token]);

    const onClickHandler = async () => {
        if (userCanReview(restaurant.token)) {
            history.push("review");
        } else {
            alert("Hai già votato per questo locale. Puoi rivotare nuovamente tra un'ora.");
        }
    }

    return (
        <Fragment>
            <button onClick={onClickHandler} className="vote block mx-auto w-[90%] mt-4 mb-2 p-2 text-center text-white bg-mycia-blue rounded-full uppercase">vota il locale</button>
            <div className="space-y-6">
                <div className="py-4 text-center">
                    <p className="font-semibold">Vuoi contribuire anche tu alla classifica ?</p>
                    <p className="px-2 text-sm">Clicca sul pulsante "VOTA IL LOCALE" per dire quali sono i piatti che hai apprezzato di più!</p>
                </div>

                {reviews.length > 0 ?
                    <Fragment>
                        <div>
                            <div className="w-1/2 ml-3 px-3 py-1 uppercase text-sm rounded-t-lg bg-mycia-green">I 3 piatti best seller</div>
                            <div className="reviews-list px-3 bg-grey-100">
                                {reviews.length > 0 ? reviews[0]?.dishes.map((review, index) =>
                                    <ReviewItem key={index} dish={review.dish} />) :
                                    <p className="no-reviews-message py-6 text-center">Ancora nessun piatto</p>}
                            </div>
                        </div>

                        <div>
                            <div className="w-1/2 ml-3 px-3 py-1 uppercase text-sm rounded-t-lg bg-yellow-400">I 3 piatti più amati</div>
                            <div className="reviews-list px-3 bg-grey-100">
                                {reviews.length > 1 ? reviews[1]?.dishes.map((review, index) =>
                                    <ReviewItem key={index} dish={review.dish} />) :
                                    <p className="no-reviews-message py-6 text-center">Ancora nessun piatto</p>}
                            </div>
                        </div>
                    </Fragment> :
                    <div>
                        <p className="text-center font-semibold">Il locale non ha ancora recensioni, sii uno dei primi a farla! Premi il tasto "Fai una recensione".</p>
                    </div>}
            </div>
        </Fragment>
    );
}

export default ReviewsTab;
