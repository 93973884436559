import axios from 'axios';

export const ALLERGIES = "https://api-ristoratori.mycia.it/v2/foodstyle/allergies/";
export const DIETS = "https://api-ristoratori.mycia.it/v2/foodstyle/diets/?diets/";
export const FOODS = "https://app.mycia.it/api/v1/application/domain/alimentiAll";

const credentials = {
    username: process.env.REACT_APP_BASIC_AUTH_USERNAME,
    password: process.env.REACT_APP_BASIC_AUTH_PASSWORD,
}

async function doGetAllergies() {
    var response = await axios.get(ALLERGIES, { auth: credentials });

    return response && response.data.response;
}

async function doGetDiets() {
    var response = await axios.get(DIETS, { auth: credentials });

    return response && response.data.response;
}

async function doGetFoods() {
    var response = await axios.get(FOODS, { auth: credentials });

    return response && response.data.response;
}

const filterModalClient = {
    doGetAllergies,
    doGetDiets,
    doGetFoods,
};
export default filterModalClient;
